import { initializeApp } from "firebase/app";

const firebaseConfig = {
	apiKey: "AIzaSyB64XUC7wUHOSfGLTwo86vJh1CcezHEZAw",
	authDomain: "vacado-b790c.firebaseapp.com",
	databaseURL: "https://vacado-b790c.firebaseio.com",
	projectId: "vacado-b790c",
	storageBucket: "vacado-b790c.appspot.com",
	messagingSenderId: "397294995709",
	appId: "1:397294995709:web:175ca4998c27c26a"
};

initializeApp(firebaseConfig);
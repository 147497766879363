import React, { memo, useState } from 'react';
import {
	Modal,
	Form,
	DatePicker,
	Select,
	Button,
	Space,
	Divider
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';

import http from '../utils/http';
import moment from 'moment';

const { Option } = Select;

const Modalcreateflightdate = ({ visible, close, onFinish }) => {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	function save({ date, from, to }){
		if(from === to){
			return Swal.fire('Oops!', 'Aeropuerto de origen y destino deben ser diferentes.', 'error')
		}

		if(['SDQ', 'STI'].includes(from) && ['SDQ', 'STI'].includes(to)){
			return Swal.fire('Oops!', 'Origen o destino incorrecto.', 'error')
		}

		if(moment(date).isBefore(moment())){
			return Swal.fire('Oops!', 'Fecha incorrecta', 'error')
		}

		setLoading(true)
		http.post('/flights/available-dates', { date, from, to })
			.then(() => {
				form.resetFields();
				onFinish();
				Swal.fire('Fecha agregada', '', 'success');
				close();
			})
			.catch(error => {
				console.error(error);
				Swal.fire('Error', 'No se pudo guardar', 'error')
			})
			.finally(() => setLoading(false))
	}

	return (
		<Modal
			visible={visible}
			title='Nueva fecha de vuelo'
			width={300}
			footer={null}
			onCancel={close}
		>
			<Form layout='vertical' onFinish={save} form={form}>
				<Form.Item
					label='Fecha'
					name='date'
					rules={[{ required: true }]}
				>
					<DatePicker style={{ width: '100%' }} />
				</Form.Item>

				<Form.Item
					label='Desde'
					name='from'
					rules={[{ required: true }]}
				>
					<Select>
						<Option value='JFK'>JFK</Option>
						<Option value='SDQ'>SDQ</Option>
						<Option value='STI'>STI</Option>
					</Select>
				</Form.Item>

				<Form.Item
					label='Hacia'
					name='to'
					rules={[{ required: true }]}
				>
					<Select>
						<Option value='JFK'>JFK</Option>
						<Option value='SDQ'>SDQ</Option>
						<Option value='STI'>STI</Option>
					</Select>
				</Form.Item>
				<Divider />

				<Space align='end'>
					<Button onClick={close}>Cancelar</Button>
					<Button
						type='primary'
						htmlType='submit'
						loading={loading}
						icon={<SaveOutlined />}
					>
						Guardar
					</Button>
				</Space>
			</Form>
		</Modal>
	);
}

export default memo(Modalcreateflightdate);

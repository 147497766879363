import axios from 'axios'

const http = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true
})

http.interceptors.response.use(
	res => res,
	error => {
		const { status } = error.response

		const path = window.location.pathname

		if((status === 401) && path !== '/'){
			window.location.href = process.env.REACT_APP_ORIGINAL_URL
		}else
			return Promise.reject(error)
	}
)

export default http;
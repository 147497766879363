import { useEffect, useState } from 'react';
import { Spin, Form, Input, Button, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSearchParams, useNavigate } from 'react-router-dom';

import http from '../utils/http';
import Swal from 'sweetalert2';

const Login = () => {
	const [params] = useSearchParams();
	const navigate = useNavigate();

	const [showForm, setShowForm] = useState(true);	
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const email = params.get('email');
		const pass = params.get('pass');

		if(email && pass){
			setShowForm(false);
			login({ email, password: pass }, true);
		}
	}, []);

	function login({ email, password }, close = false){
		setLoading(true);
		http.post('/auth/login', { email, pass: password })
			.then(({ data }) => {
				if(!data.loggedin){
					return Swal.fire('Oops!', 'Credenciales incorrectas', 'warning');
				}

				localStorage.setItem('session', JSON.stringify(data));
				if(!close){
					navigate('/home')
				}
			})
			.catch(error => {
				Swal.fire('Error', error, 'error')
			})
			.finally(() => {
				setLoading(false);
				if(close){
					Swal.fire({
						title: 'Sesion iniciada',
						text: 'Cierra esta pestaña para continuar',
						icon: 'success',
						confirmButtonText: 'Cerrar pestaña',
						allowOutsideClick: false,
					}).then(({ value }) => {
						if(value){
							window.close()
						}
					})
				}
			})
	}

	return (
		<div
			style={{
				height: '100vh',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				background: '#cdcdcd'
			}}
		>
			{showForm && (
				<Form
					layout='vertical'
					onFinish={login}
					style={{
						background: '#fff',
						padding: 15,
						borderRadius: 5
					}}
				>
					<Form.Item
						label='Email'
						name='email'
						rules={[{ required: true }]}
					>
						<Input type='email' autoFocus />
					</Form.Item>

					<Form.Item
						label='Contraseña'
						name='password'
						rules={[{ required: true }]}
					>
						<Input.Password />
					</Form.Item>

					<Row justify='center'>
						<Button
							type='primary'
							htmlType='submit'
							loading={loading}
						>
							Iniciar
						</Button>
					</Row>
				</Form>
			)}

			{!showForm && (
				<>
					<Spin indicator={<LoadingOutlined style={{ fontSize: 100 }} />} />
					<br />
					<h4>Cargando</h4>
				</>
			)}
		</div>
	);
}

export default Login;

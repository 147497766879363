import { Layout } from 'antd';
import { Routes, Route, useLocation } from 'react-router-dom';

import Navbar from './components/Navbar';

import Login from './views/Login';
import Flights from './views/Flights';
import FlightsAvailables from './views/FlightsAvailables';
import Payments from './views/Payments';

const Router = () => {
	const { pathname } = useLocation();
	const session = localStorage.getItem('session');

	if(!session || pathname === '/login'){
		return <Login />
	}

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Navbar />

			<Layout style={{ padding: 30 }}>
				<Routes>
					<Route path='/vuelos' element={<Flights />} />
					<Route path='/vuelos-disponibles' element={<FlightsAvailables />} />
					<Route path='/pagos' element={<Payments />} />
				</Routes>
			</Layout>
		</Layout>
	)
}

export default Router;
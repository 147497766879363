import React, { useState, useEffect } from 'react';
import {
	Table,
	Tag,
	Row,
	Button,
	Form,
	Input,
	DatePicker,
	Modal,
	Space,
	Select,
	Menu,
	Dropdown,
	Timeline
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import moment from 'moment';
import Swal from 'sweetalert2';

import { format, avoidNotNumerics } from '../utils/helpers';
import http from '../utils/http';

const URL = process.env.REACT_APP_ORIGINAL_URL;

const Payments = () => {
	const [reportForm] = Form.useForm();
	const [createForm] = Form.useForm();

	const [loading, sertLoading] = useState(false);
	const [creating, setCreating] = useState(false);
	const [payments, setPayments] = useState([]);
	const [search, setSearch] = useState('');
	const [createPayment, setCreatepayment] = useState(false);
	const [showReportModal, setShowReportModal] = useState(false);
	const [gettingReport, setGettingReport] = useState(false);
	const [declinados, setDeclinados] = useState();

	useEffect(() => {
		getPayments();
	}, []);

	function getPayments(){
		sertLoading(true);
		http.get('/pagos')
			.then(({ data }) => {
				const paymentList = [];
				for(const key in data ){
					paymentList.push({ id: key, ...data[key] })
				}

				setPayments(paymentList);
				sertLoading(false);
			})
			.catch(error => console.error(error))
	}

	function create(pago){
		setCreating(true);

		if(!pago.pasarela){
			pago.pasarela = 'azul'
		}

		pago.metodo = 'tcw';
		http.post('/pagos', { pago })
			.then(() => {
				Swal.fire(
					'Listo',
					'El link de pago fue enviado al email ingresado',
					'success'
				);
				setCreatepayment(false);
				getPayments();
				createForm.resetFields();
			})
			.catch(error => {
				Swal.fire(
					'Error',
					error.response.data,
					'error'
				)
			})
			.finally(() => setCreating(false))
	}

	async function setEmited(emited, id){
		const originalList = payments;

		try{
			// Actualiza interfaz
			setPayments(payments.map(payment => payment.id === id
				? ({ ...payment, emitido: emited })
				: payment)
			);

			// Actualiza DB
			await http.put('/pagos/emitido', { key: id, emitido: emited })
		}catch(error){
			setPayments(originalList)
			Swal.fire('Error', 'No se ha podido actualizar el pago', 'error');
			console.error(error);
		}
	}

	function filteredPayments(){
		if(search.trim() === ''){
			return payments;
		}

		return payments.filter(({ no_orden, cliente, servicio, email }) => (
			no_orden === search ||
			cliente.toLowerCase().includes(search.toLowerCase()) ||
			`${servicio}`.toLowerCase().includes(search.toLowerCase()) ||
			email.toLowerCase().includes(search.toLowerCase())
		))
	}

	async function generateReport({ dateFrom, dateTo }){
		try {
			setGettingReport(true);
			const { data } = await http.get(
				'/pagos/get-excel',
				{
					params: {
						dateFrom: dateFrom.format('YYYY-MM-DD'),
						dateTo: dateTo.format('YYYY-MM-DD')
					},
					responseType: 'blob'
				}
			);
			const url = window.URL.createObjectURL(new Blob([data]))
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'Payments Report.csv'); //or any other extension
			document.body.appendChild(link);
			link.click();
			reportForm.resetFields();
			setShowReportModal(false);
		} catch (error) {
			console.error(error)
		} finally {
			setGettingReport(false);
		}
	}

	return (
		<div>
			<Row justify='space-between'>
				<Space>
					<Button type='primary' onClick={() => setCreatepayment(true)}>Nuevo Pago</Button>
					<Button onClick={() => setShowReportModal(true)}>Report Excel</Button>
				</Space>

				<Form>
					<Form.Item label='Buscar'>
						<Input onChange={({ target }) => setSearch(target.value)} />
					</Form.Item>
				</Form>
			</Row>

			<Table
				dataSource={filteredPayments()}
				rowKey={({ id }) => id}
				loading={loading}
				pagination={{
					pageSize: 100
				}}
				scroll={{ x: true }}
				columns={[
					{
						title: '#ID',
						dataIndex: 'fecha',
						render: date => moment(date).toDate().getTime(),
						sorter: (a, b) => moment(a.fecha).toDate() - moment(b.fecha).toDate(),
						defaultSortOrder: 'descend',
						ellipsis: true
					},
					{
						title: '# Orden',
						dataIndex: 'no_orden',
						ellipsis: true
					},
					{
						title: 'Fecha Emision',
						ellipsis: true,
						dataIndex: 'fecha',
						render: date => moment(date).format('DD MMM YYYY hh:mm A').replace('.', '').toUpperCase(),
						sorter: (a, b) => moment(a.fecha).toDate() - moment(b.fecha).toDate()
					},
					{
						title: 'Fecha Pago',
						ellipsis: true,
						dataIndex: 'fecha_pago',
						render: date => (date) ? moment(date).format('DD MMM YYYY hh:mm A').replace('.', '').toUpperCase(): 'N/A',
						sorter: (a, b) => moment(a.fecha_pago).toDate() - moment(b.fecha_pago).toDate()
					},
					{
						title: 'Cliente',
						ellipsis: true,
						dataIndex: 'cliente'
					},
					{
						title: 'Email',
						ellipsis: true,
						dataIndex: 'email'
					},
					{
						title: 'Servicio',
						ellipsis: true,
						dataIndex: 'servicio'
					},
					{
						title: 'Monto',
						ellipsis: true,
						dataIndex: 'monto',
						align: 'right',
						render: amount => format.cash(amount),
						sorter: (a, b) => a.monto - b.monto
					},
					{
						title: 'IP',
						ellipsis: true,
						dataIndex: 'ip',
						render: ip => (ip) ? ip.replace(/f|:/g, ''): '--'
					},
					{
						title: 'Pasarela',
						ellipsis: true,
						dataIndex: 'pasarela',
						render: (pasarela, { metodo }) => {
							if(metodo !== 'tcw'){
								return 'N/A'
							}

							return pasarela || 'Azul'
						},
						filters: [
							{
								value: 'azul',
								text: 'Azul'
							},
							{
								value: 'paypal',
								text: 'Paypal'
							}
						],
						onFilter: (value, { pasarela }) => {
							if(!pasarela){
								pasarela = 'azul';
							}

							return pasarela === value;
						},
					},
					{
						title: 'Metodo de pago',
						ellipsis: true,
						dataIndex: 'metodo',
						render: metodo => {
							const metodos = {
								tcw: 'Tarjeta (Online)',
								tcv: 'Tarjeta (Oficina)',
								efectivo: 'Efectivo',
								undefined: 'Tarjeta (Oficina)'
							}

							return metodos[metodo]
						},
						filters: [
							{
								value: 'tcw',
								text: 'Tarjeta (Online)'
							},
							{
								value: 'tcv',
								text: 'Tarjeta (Oficina)'
							},
							{
								value: 'efectivo',
								text: 'Efectivo'
							}
						],
						onFilter: (value, { metodo }) => {
							if(!metodo){
								metodo = 'tcv'
							}

							return value === metodo
						}
					},
					{
						title: 'Usuario',
						ellipsis: true.valueOf,
						dataIndex: 'nombre_usuario_creador'
					},
					{
						title: 'Pagado',
						dataIndex: 'realizado',
						ellipsis: true,
						filters: [
							{
								value: true,
								text: 'Si'
							},
							{
								value: false,
								text: 'No'
							}
						],
						onFilter: (value, { realizado }) => realizado === value,
						render: payed => {
							if(payed){
								return <Tag color='success'>Si</Tag>
							}

							return <Tag color='red'>No</Tag>
						}
					},
					{
						title: 'Emitido',
						dataIndex: 'emitido',
						align: 'center',
						filters: [
							{
								value: true,
								text: 'Si'
							},
							{
								value: false,
								text: 'No'
							}
						],
						onFilter: (value, { emitido }) => {
							if(!emitido){
								emitido = false;
							}

							return emitido === value;
						},
						render: (emitido) => {
							if(emitido){
								return <Tag color='success'>Si</Tag>
							}
							
							return <Tag color='red'>No</Tag>
						}
					},
					{
						title: 'Opciones',
						ellipsis: true,
						align: 'center',
						render: (_, { id, metodo, emitido, declinados }) => {
							return (
								<Dropdown
									overlay={
										<Menu>
											<Menu.Item disabled={metodo !== 'tcw'}>
												<a
													href={URL + '/pago/' + id}
													target='_blank'
													title='Ir al link de pago'
													rel='noreferrer'
												>
													Abrir Link
												</a>
											</Menu.Item>
											<Menu.Item
												disabled={emitido}
												onClick={() => setEmited(true, id)}
											>
												Marcar como emitido
											</Menu.Item>
											<Menu.Item
												disabled={!declinados}
												onClick={() => setDeclinados(declinados)}
											>
												Historial de declinaciones
											</Menu.Item>
										</Menu>
									}
								>
									<MoreOutlined />
								</Dropdown>
								
							)
						}
					}
				]}
			/>

			<Modal
				title='Nuevo Pago'
				visible={createPayment}
				onCancel={() => setCreatepayment(false)}
				width={300}
				footer={null}
			>
				<Form layout='vertical' onFinish={create} form={createForm}>
					<Form.Item
						label='Cliente'
						rules={[{ required: true }]}
						name='cliente'
					>
						<Input autoFocus />
					</Form.Item>

					<Form.Item
						label='Email'
						rules={[{ required: true }]}
						name='email'
					>
						<Input type='email' />
					</Form.Item>

					<Form.Item
						label='Servicio'
						rules={[{ required: true }]}
						name='servicio'
					>
						<Input />
					</Form.Item>

					<Form.Item
						label='Monto'
						rules={[{ required: true }]}
						name='monto'
					>
						<Input onKeyDown={avoidNotNumerics} />
					</Form.Item>

					<Form.Item
						label='Pasarela'
						name='pasarela'
					>
						<Select defaultValue='azul'>
							<Select.Option value='azul'>
								Azul
							</Select.Option>
							<Select.Option value='paypal'>
								Paypal
							</Select.Option>
						</Select>
					</Form.Item>

					<Space>
						<Button onClick={() => setCreatepayment(false)}>Cerrar</Button>
						<Button
							type='primary'
							htmlType='submit'
							loading={creating}
							disabled={creating}
						>
							Crear
						</Button>
					</Space>
				</Form>
			</Modal>

			<Modal
				title='Generar reporte CSV'
				visible={showReportModal}
				onCancel={() => setShowReportModal(false)}
				width={300}
				footer={null}
			>
				<Form
					onFinish={generateReport}
					layout='vertical'
					form={reportForm}
				>
					<Form.Item
						name='dateFrom'
						label='Desde'
						rules={[{
							required: true
						}]}
					>
						<DatePicker style={{ width: '100%' }} />
					</Form.Item>

					<Form.Item
						name='dateTo'
						label='Hasta'
						rules={[{
							required: true
						}]}
					>
						<DatePicker style={{ width: '100%' }} />
					</Form.Item>

					<Button
						type='primary'
						htmlType='submit'
						loading={gettingReport}
					>
						Generar reporte
					</Button>
				</Form>
			</Modal>

			<Modal
				visible={declinados}
				onCancel={() => setDeclinados()}
				title='Historial'
				width={400}
				footer={null}
			>
				<Timeline mode='left'>
					{declinados?.map(({ DateTime, ResponseMessage }) => (
						<Timeline.Item
							color='red'
							label={moment(format.custom(DateTime, '0000-00-00 00:00:00')).format('DD MMM YY - hh:mm A').replace('.', '').toUpperCase()}
						>
							{ResponseMessage}
						</Timeline.Item>
					))}
				</Timeline>
			</Modal>
		</div>
	);
}

export default Payments;
import React, { memo, useState } from 'react';
import { Layout, Menu } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { NavLink, useNavigate } from 'react-router-dom';

import { GiCommercialAirplane, GiAirplane, GiCircleForest } from 'react-icons/gi';
import { SiCashapp } from 'react-icons/si';
import { MdOutlineLocalOffer } from 'react-icons/md';
import { MdPayment, MdOutlineHotel } from 'react-icons/md';
import { IoMdGlobe, IoIosBoat } from 'react-icons/io';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { AiOutlineRead, AiOutlineHistory } from 'react-icons/ai';
import { ImUsers } from 'react-icons/im';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { RiUserSettingsLine } from 'react-icons/ri';
import {
	InfoCircleOutlined,
	FileSearchOutlined,
	FlagOutlined,
	PoweroffOutlined
} from '@ant-design/icons';

const { Sider } = Layout;
const { SubMenu } = Menu;
const URL = process.env.REACT_APP_ORIGINAL_URL;
const menuCollapsed = JSON.parse(localStorage.getItem('menuCollapsed') || 'false');

const Navbar = () => {
	const [collapsed, setCollapsed] = useState(menuCollapsed);
	const navigate = useNavigate();

	function toggleMenu(){
		localStorage.setItem('menuCollapsed', JSON.parse(!collapsed))
		setCollapsed(!collapsed)
	}

	function logout(){
		localStorage.removeItem('session');
		navigate('/login')
	}

	return (
		<Sider
			collapsible collapsed={collapsed}
			onCollapse={toggleMenu}
		>
			<Menu
				theme='dark'
				defaultSelectedKeys={[window.location.pathname]}
				mode='inline'
			>
				<Menu.Item key='/home' icon={<HomeOutlined />}>
					<a href={URL + '/admin/home'}>Home</a>
				</Menu.Item>
				<SubMenu key='/info' icon={<InfoCircleOutlined />} title='Informaciones'>
					<Menu.Item key='/conde' icon={<HiOutlineDocumentText />}>
						<a href={URL + '/admin/condiciones_de_uso'}>Terminos de uso</a>
					</Menu.Item>
					<Menu.Item key='/sobre' icon={<AiOutlineRead />}>
						<a href={URL + '/admin/sobre_nosotros'}>Sobre Nosotros</a>
					</Menu.Item>
					<Menu.Item key='/politicas' icon={<HiOutlineDocumentText />}>
						<a href={URL + '/admin/politicas_de_privacidad'}>Politicas de privacidad</a>
					</Menu.Item>
					<Menu.Item key='/finan' icon={<SiCashapp />}>
						<a href={URL + '/admin/financiamiento'}>Financiamiento</a>
					</Menu.Item>
				</SubMenu>
				<SubMenu key='/servicios' icon={<FileSearchOutlined />} title='Servicios'>
					<Menu.Item key='/hoteles' icon={<MdOutlineHotel />}>
						<a href={URL + '/admin/hoteles'}>Hoteles</a>
					</Menu.Item>
					<Menu.Item key='/excursiones' icon={<GiCircleForest />}>
						<a href={URL + '/admin/excursiones'}>Excursiones</a>
					</Menu.Item>
					<Menu.Item key='/ferrie' icon={<IoIosBoat />}>
						<a href={URL + '/admin/ferrie'}>Ferrie</a>
					</Menu.Item>
					<Menu.Item key='/internacionales' icon={<IoMdGlobe />}>
						<a href={URL + '/admin/internacionales'}>Internacionales</a>
					</Menu.Item>
					<Menu.Item key='/ofertas' icon={<MdOutlineLocalOffer />}>
						<a href={URL + '/admin/ofertas'}>Ofertas de temporada</a>
					</Menu.Item>
					<Menu.Item key='/ftn' icon={<GiAirplane />}>
						<a href={URL + '/admin/flight-to-nowhere'}>Flight To Nowhere</a>
					</Menu.Item>
					<Menu.Item key='/ven' icon={<FlagOutlined />}>
						<a href={URL + '/admin/venezuela'}>Venezuela</a>
					</Menu.Item>
					<Menu.Item key='/vuelos' icon={<GiCommercialAirplane />}>
						<NavLink to='/vuelos'>Vuelos</NavLink>
					</Menu.Item>
				</SubMenu>
				<Menu.Item key='/clientes' icon={<ImUsers />}>
					<a href={URL + '/admin/clientes'}>Clientes</a>
				</Menu.Item>
				<Menu.Item key='/cotizaciones' icon={<FaFileInvoiceDollar />}>
					<a href={URL + '/admin/cotizaciones'}>Cotizaciones</a>
				</Menu.Item>
				<Menu.Item key='/pagos' icon={<MdPayment />}>
					<NavLink to={'/pagos'}>Pagos</NavLink>
				</Menu.Item>
				<Menu.Item key='/usuarios' icon={<RiUserSettingsLine />}>
					<a href={URL + '/admin/usuarios'}>Usuarios</a>
				</Menu.Item>
				<Menu.Item key='/historial' icon={<AiOutlineHistory />}>
					<a href={URL + '/admin/historial'}>Historial</a>
				</Menu.Item>
				<Menu.Item key='logout' icon={<PoweroffOutlined />} onClick={logout}>
					Cerrar session
				</Menu.Item>
			</Menu>
		</Sider>
	);
}

export default memo(Navbar);

import React, { memo } from 'react';
import { Modal, Row, Col, Table, Button } from 'antd';
import moment from 'moment';

const Modalflightdetails = ({ visible, flight, close, flightTypes }) => {
	return (
		<Modal
			visible={visible}
			onCancel={close}
			title='Detalles del vuelo'
			width={1000}
			footer={[
				<Button
					type='primary'
					onClick={close}
				>
					Cerrar
				</Button>
			]}
		>
			<Row gutter={15}>
				<Col span={6}>
					<label>Tipo</label>
					<p>{flightTypes[flight?.type - 1]}</p>

					<label># Orden</label>
					<p>{flight?.orderNumber}</p>

					<label>Pago</label>
					{flight?.paymentId && (
						<p>
							<a href={process.env.REACT_APP_ORIGINAL_URL + '/admin/pago/' + flight.paymentId}>Ver Pago</a>
						</p>
					)}
					{!flight?.paymentId && (
						<p>No Pagado</p>
					)}
				</Col>

				<Col span={6}>
					<label>PNR</label>
					<p>{flight?.pnr || 'N/A'}</p>

					<label>Fecha Solicitud</label>
					<p>{moment(flight?.createdAt).format('DD MMM YYYY').replace('.', '').toUpperCase()}</p>

					<label>Adultos</label>
					<p>{flight?.adults}</p>
				</Col>

				<Col span={6}>
					<label>Fecha de Salida</label>
					<p>{moment(flight?.dateFrom).format('DD MMM YYYY').replace('.', '').toUpperCase()}</p>

					<label>Ciudad de Salida</label>
					<p>{flight?.departureCity}</p>

					<label>Niños</label>
					<p>{flight?.childs}</p>
				</Col>

				<Col span={6}>
					<label>Fecha de Regreso</label>
					<p>{(flight?.dateTo) ? moment(flight?.dateTo).format('DD MMM YYYY').replace('.', '').toUpperCase(): 'N/A'}</p>

					<label>Ciudad de Regreso</label>
					<p>{flight?.destinationCity}</p>

					<label>Infantes</label>
					<p>{flight?.infants}</p>
				</Col>
			</Row>
			<br />

			<label>Pasajeros</label>
			<Table
				dataSource={flight?.passengers}
				pagination={{ hideOnSinglePage: true }}
				columns={[
					{
						title: 'Nombre',
						dataIndex: 'firstName',
						render: (firstName, { lastName }) => firstName + ' ' + lastName
					},
					{
						title: 'Nacimiento',
						dataIndex: 'birthDate',
						render: date => moment(date).format('DD MMM YYYY').replace('.', '').toUpperCase()
					},
					{
						title: 'Email',
						dataIndex: 'email',
					},
					{
						title: 'Telefono',
						dataIndex: 'phone',
					},
					{
						title: 'Genero',
						dataIndex: 'gender',
					},
					{
						title: 'Nac.',
						dataIndex: 'nationality',
					},
					{
						title: 'Pasaporte',
						dataIndex: 'passport',
					},
					{
						title: 'Pasaporte Exp',
						dataIndex: 'passportExpDate',
						render: date => moment(date).format('DD MMM YYYY').replace('.', '').toUpperCase()
					},
				]}
			/>
		</Modal>
	);
}

export default memo(Modalflightdetails);

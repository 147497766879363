import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import {
	Table,
	Row,
	Button,
	Form,
	DatePicker
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

import http from '../utils/http'
import ModalCreate from '../components/ModalCreateFlightDate';

const Flightsavailable = () => {
	const [loading, setLoading] = useState(true);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [flights, setFlights] = useState([]);
	const [selectedDate, setSelectedDate] = useState('');

	useEffect(() => loadFlights(), [])

	function loadFlights(){
		http.get('/flights/available-dates')
			.then(({ data }) => setFlights(data))
			.catch(error => {
				Swal.fire('Oops!', 'No se pudo obtener la lista de vuelos', 'warning');
				console.error(error);
			})
			.finally(() => setLoading(false))
	}

	async function deleteFlight(id, { from, to, date }){
		try {
			const { value } = await Swal.fire({
				icon: 'warning',
				title: 'Está seguro?',
				text: `Está a punto de eliminar ${from} - ${to} ${moment(date).format('DD MMM YYYY').replace('.', '').toUpperCase()}`,
				showCancelButton: true,
				cancelButtonText: 'Cacelar',
				confirmButtonText: 'Eliminar',
				reverseButtons: true
			})

			if(!value){
				return;
			}
		
			setLoading(true)
			await http.delete('/flights/available-dates/' + id);
			Swal.fire('Eliminado', '', 'success')
		} catch (error) {
			Swal.fire('Oops!', 'No se pudo eliminar', 'warning');
			console.error(error);
		} finally {
			loadFlights()
		}
	}

	function filteredFlights(){
		if(selectedDate === ''){
			return flights;
		}

		return flights.filter(({ date }) => date === selectedDate)
	}

	return (
		<>
			<Row justify='space-between'>
				<Button
					icon={<PlusOutlined />}
					type='primary'
					onClick={() => setShowCreateModal(true)}
				>
					Agregar Vuelo
				</Button>

				<Form>
					<Form.Item label='Fecha'>
						<DatePicker onChange={(_, date) => setSelectedDate(date)} />
					</Form.Item>
				</Form>
			</Row>

			<Table
				loading={loading}
				dataSource={filteredFlights()}
				pagination={{ pageSize: 100 }}
				rowKey={({ id }) => id}
				columns={[
					{
						title: 'Fecha',
						dataIndex: 'date',
						defaultSortOrder: 'ascend',
						render: (date) => moment(date).format('DD MMM YYYY').replace('.', '').toUpperCase(),
						sorter: (a, b) => new Date(a.date) - new Date(b.date)
					},
					{
						title: 'Desde',
						dataIndex: 'from',
						filters: [
							{
								text: 'JFK',
								value: 'JFK'
							},
							{
								text: 'SDQ',
								value: 'SDQ'
							},
							{
								text: 'STI',
								value: 'STI'
							}
						],
						onFilter: (val, { from }) => val === from
					},
					{
						title: 'Hacia',
						dataIndex: 'to',
						filters: [
							{
								text: 'JFK',
								value: 'JFK'
							},
							{
								text: 'SDQ',
								value: 'SDQ'
							},
							{
								text: 'STI',
								value: 'STI'
							}
						],
						onFilter: (val, { to }) => val === to
					},
					{
						title: 'Opciones',
						dataIndex: 'id',
						align: 'center',
						render: (id, record) =>
							<Button
								icon={<DeleteOutlined color='#330000' />}
								onClick={() => deleteFlight(id, record)}
							>
								Eliminar
							</Button>
					}
				]}
			/>

			<ModalCreate
				visible={showCreateModal} 
				onFinish={() => loadFlights()}
				close={() => setShowCreateModal(false)}
			/>
		</>
	);
}

export default Flightsavailable;

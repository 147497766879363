import React, { useState, useEffect } from 'react';
import {
	Table,
	Tag,
	Row,
	Form,
	Input,
	Button,
	Dropdown,
	Menu
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import localization from 'moment/locale/es-do';
import Swal from 'sweetalert2';

import http from '../utils/http';
import { format } from '../utils/helpers';
import ModalFlightDetails from '../components/ModalFlightDetails';

moment.updateLocale('es-do', localization)
const flightTypes = ['One Way', 'Round Trip'];

const Flights = () => {
	const [loading, setLoading] = useState(true);
	const [flights, setFlights] = useState([]);
	const [selectedFlight, setSelectedFlight] = useState(null);
	const [search, setSearch] = useState('');

	useEffect(() => {
		http.get('/flights')
			.then(({ data }) => setFlights(data))
			.catch((error) => {
				console.log(error)
				Swal.fire('Error', 'No se pudo obtener la lista de vuelos', 'error')
			})
			.finally(() => setLoading(false))
	}, [])

	function filteredFlights(){
		if(search.trim() === '')
			return flights

		const phrase = search.trim().toLowerCase();
		return flights.filter(flight => {
			return (
				`${flight?.pnr}`.toLowerCase().includes(phrase) ||
				flight.orderNumber.includes(phrase) ||
				flight.passengers.some(passenger => (
					passenger.firstName.toLowerCase().includes(phrase) ||
					passenger.lastName.toLowerCase().includes(phrase) ||
					passenger.email.toLowerCase().includes(phrase)
				))
			)
		})
	}

	return (
		<>
			<Row justify='space-between'>
				<Button type='primary'>
					<NavLink to='/vuelos-disponibles'>
						Vuelos Disponibles
					</NavLink>
				</Button>

				<Form>
					<Form.Item label='Buscar'>
						<Input onChange={({ target }) => setSearch(target.value)} />
					</Form.Item>
				</Form>
			</Row>
			<Table
				scroll={{ x: true }}
				loading={loading}
				dataSource={filteredFlights()}
				pagination={{ defaultPageSize: 100 }}
				columns={[
					{
						ellipsis: true,
						title: 'Fecha',
						dataIndex: 'createdAt',
						render: (date) => moment(date)
							.format('DD MMM YY hh:mm A')
							.replace('.', '')
							.toUpperCase(),
						defaultSortOrder: 'descend',
						sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt)
					},
					{
						ellipsis: true,
						title: 'Cliente',
						dataIndex: 'passengers',
						render: ([passenger]) => {
							if(!passenger)
								return '';

							const { firstName, lastName } = passenger;
							return firstName + ' ' + lastName
						}
					},
					{
						ellipsis: true,
						title: 'Email',
						dataIndex: 'passengers',
						render: ([passenger]) => {
							if(!passenger)
								return '';

							const { email } = passenger;
							return <a href={`mailto:${email}`} target='_blank' rel='noreferrer'>{email}</a>
						}
					},
					{
						ellipsis: true,
						title: 'Telefono',
						dataIndex: 'passengers',
						render: ([passenger]) => {
							if(!passenger)
								return '';

							const { phone } = passenger;
							return <a href={`phone:${phone}`} target='_blank' rel='noreferrer'>{phone}</a>
						}
					},
					{
						ellipsis: true,
						title: 'Tipo',
						dataIndex: 'type',
						render: (type) => {
							return <Tag color={(type === 2) ? 'blue': 'warning'}>{flightTypes[type - 1]}</Tag>
						},
						filters: [
							{
								text: 'One Way',
								value: 1
							},
							{
								text: 'Round Trip',
								value: 2
							}
						],
						onFilter: (value, record) => record.type === value
					},
					{
						ellipsis: true,
						title: 'PNR',
						dataIndex: 'pnr'
					},
					{
						ellipsis: true,
						title: 'Fecha de ida',
						dataIndex: 'dateFrom',
						sorter: (a, b) => moment(a.dateFrom) - moment(b.dateFrom),
						render: (date) => moment(date)
							.format('DD MMM YY')
							.replace('.', '')
							.toUpperCase()
					},
					{
						ellipsis: true,
						title: 'Pax',
						dataIndex: 'adults',
						render: (adults, { childs, infants }) => adults + childs + infants,
						sorter: (a, b) => {
							return (a.adults + a.childs + a.infants) - (b.adults + b.childs + b.infants)
						}
					},
					{
						ellipsis: true,
						title: 'Ruta',
						dataIndex: 'departureCity',
						render: (departure, { destinationCity }) => departure + ' - ' + destinationCity
					},
					{
						ellipsis: true,
						title: 'Monto US$',
						dataIndex: 'total',
						render: (amount) => format.cash(amount),
						sorter: (a, b) => a.total - b.total
					},
					{
						ellipsis: true,
						title: 'Pagado',
						dataIndex: 'payed',
						render: (payed) => {
							return <Tag color={payed ? 'success': 'error'}>{(payed) ? 'Si': 'No'}</Tag>
						},
						filters: [
							{
								text: 'Si',
								value: true
							},
							{
								text: 'No',
								value: false
							}
						],
						onFilter: (value, record) => record.payed === value
					},
					{
						ellipsis: true,
						title: 'Opciones',
						dataIndex: 'id',
						align: 'center',
						render: (_, record) => (
							<Dropdown
								overlay={
									<Menu>
										<Menu.Item onClick={() => setSelectedFlight(record)}>
											Ver detalles
										</Menu.Item>
										{record.paymentId && (
											<Menu.Item>
												{/* <NavLink to={'/pago/' + record.paymentId}>Ver Pago</NavLink> */}
												<a href={process.env.REACT_APP_ORIGINAL_URL + '/admin/pago/' + record.paymentId}>Ver Pago</a>
											</Menu.Item>
										)}
									</Menu>
								}
							>
								<MoreOutlined />
							</Dropdown>
						)
					}
				]}
				rowKey={({ id }) => id}
			/>

			<ModalFlightDetails
				visible={(selectedFlight)}
				close={() => setSelectedFlight(null)}
				flight={selectedFlight}
				flightTypes={flightTypes}
			/>
		</>
	);
}

export default Flights;
